import { mapActions } from 'vuex'
export default {
  name: 'forgot-password-modal',
  components: {
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField'),
    Close: () => import(/* webpackChunkName: "icon-close" */ '@/components/icons/Close'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      email: '',
      emailError: null,
      mappedState: {
        academy: 'G2Academy',
        prakerja: 'Prakerja'
      }
    }
  },
  props: {
    isNewLoginFlow: {
      type: String,
      default: 'false'
    },
    state: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('auth', ['resetPasswordPrakerja', 'resetPasswordCRM']),
    send() {
      this.emailError = null
      // eslint-disable-next-line
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.email === '') {
        this.emailError = 'Email is required!'
        return
      } else if (!regex.test(this.email)) {
        this.emailError = 'Invalid email format!'
        return
      } else {
        this.emailError = null
      }

      if (!this.emailError) {
        this.showLoading()
        this.resetPasswordPrakerja({
          payloads: {
            email: this.email
          }
        }).then(this.successResetPassword, this.errorResetPassword)
      }
    },
    successResetPassword(res) {
      if (res.data.code === 422) {
        this.emailError = 'Your email is not registered in our system!'
      } else {
        this.$emit('success', this.email)
      }
      this.hideLoading()
    },
    errorResetPassword(res) {
      this.hideLoading()
      if (res.response.data.code === 404) {
        this.emailError = 'Your email is not registered in our system!'
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
